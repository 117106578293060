.dashboard_main{
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0px 10%;

.dashBoard{
    width: 100%;
    height: 95dvh;
    padding: 40px;
    h2{
        padding-bottom: 10px;
    }
    .dashBoard_in{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 30px;
        .dashBoard_item{
            width: 100%;
            height: 200px;
            background-color: white;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        
    }

    .dashBoard_data_main{
        width: 100%;
        margin-top: 30px;
        .dashBoard_data{
        border-radius: 5px;
        display: grid;
        grid-template-columns: 2fr 1fr ;
        align-items: center;
        justify-content: center;
        padding: 20px;
            width: 100%;
            height: auto;
             background-color: white;
        }

    }

    
}

}
