.subHeader{
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 20px;
    background-color: white;
    border-bottom:1px solid rgb(234, 234, 234) ; 
    ul{
        display: flex;
        gap: 30px;
        
        li{
            padding: 10px 20px;
            border: 1px solid #1976d2;;
            list-style: none;
            border-radius: 30px;
            color: #1976d2;
            font-size: 14px;
            transition: .2s ease-in-out;
        }
        li:hover{
            transition: .2s ease-in-out;
            background-color: #c8e4ff5c;;
        }

    }
}