.login {
  height: auto;
  margin-top: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height:100dvh;

  ._log_in {
   // background-color:#edededaa ;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 100px;
    ._in_h{
        width: 100%;
        height: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding: 20px;
        h2{
          color: #3b82f6;
        }
        span{
          color: grey;
        }
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      .select_input{
             border: 1px solid rgba(170, 170, 170, 0.6666666667);
    border-radius: 20px;
    width: 100%;
    padding: 10px 15px !important;
    margin-bottom: 10px;

      }

      input {
        border: 1px solid rgba(170, 170, 170, 0.6666666667);
    border-radius: 20px;
    width: 100%;
    padding: 10px 15px !important;
    margin-bottom: 10px;
      }
      button {
        //border: 1px solid #aaaa;
        outline: none !important;
        border-radius: 20px;
        width: 100%;
        padding: 10px 20px;
        margin-bottom: 20px;
        cursor: pointer;
        border: none;
        transition: 0.3s;
        color: white;
        background-color:#3b82f6;
      }
      button:hover{
          background-color: #d9d9d9;
            transition: 0.3s ease-in;
            color: rgb(49, 49, 49);
                       // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.233), 0 4px 12px rgba(0, 0, 0, 0.231);
          
      }
      ._rg_pg{
align-items: center;
justify-content: center;
        height: auto;
        display: flex;

        span{
            font-size: 12px;
            color: grey;
            text-decoration: none !important;
            font-style: none !important;
        }


      }
      
    }
  }
}
