.container {
  display: flex;
  justify-content: center;
  align-items: center;
  .fade-in-out {
    opacity: 1;
    transition: opacity 2s ease-in-out; /* Adjust the transition duration for smoother fade-in */
  }

  .fade-out {
    opacity: 0;
    transition: opacity 2s ease-in-out; /* Adjust the transition duration for smoother fade-out */
  }
}