.ad_new {
  .addNew_in {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100dvh;
    z-index: 99;
    backdrop-filter: blur(2px);
    background-color: #1f2937d7;
    z-index: 999;
    position: fixed;
    .addNew_wrap {
      height: 700px;
      border-radius: 5px;
      padding: 2rem;
      overflow: hidden;
      width: 80% !important;
      background-color: white;
      position: relative;
      .approve_wrp {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        padding: 0rem 1rem;
        .approve_wrp_head {
          margin-bottom: 5px;
          display: flex;
          justify-content: flex-end;
        }
        .ap_wrp {
          display: flex;
          justify-content: flex-end;
          gap: 20px;
          .ap_item {
            align-items: center;
            justify-content: center;
            padding: 10px;
            display: flex;
            flex-direction: column;
          }
        }
      }

      .add_form {
        max-height: 600px !important;
        overflow: scroll !important;
        display: flex;
        flex-direction: column;
        .add_form_wrap {
          width: 100%;
          display: grid;
          grid-gap: 50px;
          grid-template-columns: 1fr 1fr;
          .form_in {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
          }
          .input {
            width: 100%;
            height: auto;
            padding: 10px;
            display: flex;
            flex-direction: column;
            select {
              margin-top: 5px;
              width: 100%;
              height: auto;
              border-radius: 5px;
              padding: 10px;
              outline: none;
              border: none;
              // border: 1px solid #d6d6d6 !important;
              background-color: rgba(229, 231, 235, 0.5058823529);
            }
            .photo_grid {
              width: 100%;
              height: auto;
              gap: 10px;
              display: grid;
              grid-template-columns: 1fr 1fr;
              .img_over {
                border-radius: 5px;
                width: 200px;
                height: 200px;
                display: flex;
                overflow: hidden !important;
                align-items: center;
                justify-content: center;
                object-fit: cover;
                position: relative;

                img {
                  display: flex;
                  object-fit: contain;
                  height: auto;
                  height: 100%;
                  width: auto;
                  overflow: hidden;
                }
                .trash_icon {
                  bottom: 3px;
                  padding: 3px;
                  border-radius: 50px;
                  right: 2px;
                  font-size: small !important;
                  position: absolute;
                  button {
                    background-color: none !important;
                    height: auto;
                    outline: none;
                    border: none;
                    border-radius: 50%;
                    padding: 3px;
                    height: 22px;
                    cursor: pointer;
                    color: rgb(0, 0, 0);
                  }
                  button:hover {
                    background-color: rgba(0, 0, 0, 0.667);
                    color: white;
                  }
                }
                .star_icon {
                  bottom: 3px;
                  padding: 3px;
                  border-radius: 50px;
                  left: 2px;

                  font-size: small !important;
                  position: absolute;
                  button {
                    background-color: none !important;
                    height: auto;
                    outline: none;
                    border: none;
                    border-radius: 50%;
                    height: 22px;
                    padding: 3px;
                    cursor: pointer;
                    color: rgb(0, 0, 0);
                  }
                  button:hover {
                    background-color: rgba(0, 0, 0, 0.667);
                    color: white;
                  }
                }
              }
              .img_upload {
                cursor: pointer;
                padding: 30px;
                display: flex;
                border-radius: .5rem;
                width: 100%;
                border: 1px solid #1976d256 !important;
                border-style: dashed !important;
              }
            }

            input {
              margin-top: 5px;
              width: 100%;
              height: auto;
              padding: 10px;
              outline: none;
              border: none;
              // border: 1px solid #d6d6d6 !important;
              background-color: rgba(229, 231, 235, 0.5058823529);
            }
            textarea {
              outline: none;
              border: none;
              margin-top: 5px;
              border-radius: 5px;
              padding: 5px 10px;
              // border: 1px solid #d6d6d6 !important;
              background-color: rgba(229, 231, 235, 0.5058823529);
            }
          }
        }

        .save {
          width: 100%;
          height: auto;
          padding-top: 10px;
          display: flex;
          justify-content: flex-end;
        }
      }
      .close {
        width: 100%;
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .btn_upload {
    width: 100%;
    height: 100%;
    display: none;
  }
}
