.inventory_main {
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0px 10%;
  .inv_left {
    margin-right: 100px;
  }
  input {
    border-radius: 5px !important;
  }
  label {
    font-size: 13px;
    font-weight: 600;
  }
  button {
    outline: none;
    border: none;
  }
  .product_info_wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    .btnWrap {
      width: 100%;
      align-items: flex-end;
      justify-content: flex-end;
      display: flex;
    }
    .approve_wrp {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        padding: 0rem 1rem;
        .approve_wrp_head {
          margin-bottom: 5px;
          display: flex;
          justify-content: flex-end;
        }
        .ap_wrp {
          display: flex;
          justify-content: flex-end;
          gap: 20px;
          .ap_item {
            align-items: center;
            justify-content: center;
            padding: 10px;
            display: flex;
            flex-direction: column;
          }
        }
      }
  }
  .inventory {
    width: 100%;
    height: 95dvh;
    padding: 40px;

    .inventory_head {
      width: 100%;
      background-color: white;
      margin-bottom: 30px;
      height: 200px;
      padding: 40px;
      border-radius: 5px;
    }

    .table {
      cursor: pointer;
      width: 100%;
      border-radius: 5px;
      background-color: white;
      padding: 20px 40px;
      display: flex;
      flex-direction: column;
      border: 1px solid #e5e7eb !important;
      border-collapse: separate;
      border-spacing: 2px;
      border-color: #e5e7eb;
      font-size: 0.875rem;

      .product_pro {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
        height: auto;
        .product_item {
          width: 100%;
          margin-bottom: -10px;

          p {
            margin-top: 5px !important;
            border-radius: 5px;
            padding: 8px 10px;
            background-color: #e5e7eb81;
          }
        }
      }

      .table_head {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        ._search {
          padding: 20px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: start;
          flex-direction: column;

          .upl_btn{
            margin-right: 20px;
            span {
            cursor: pointer;
            padding: 5px 20px;
            background-color: #1976d2;
            border-radius: 5px;
            color: white;
          }
          span:hover {
            background-color: #5596ff;
            transition: 0.2s ease-in-out;
          }

          }
          .upload_wrp{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;

          }
          
        }
        .addNew {
          padding: 20px;
          width: 100%;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          span {
            cursor: pointer;
            padding: 5px 20px;
            background-color: #1976d2;
            border-radius: 5px;
            color: white;
          }
          span:hover {
            background-color: #5596ff;
            transition: 0.2s ease-in-out;
          }
          .addNew_in {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100dvh;
            z-index: 99;
            backdrop-filter: blur(2px);
            background-color: #1f2937d7;
            z-index: 999;
            position: fixed;
            .addNew_wrap {
               height: 700px;
      border-radius: 5px;
      padding: 2rem;
      overflow: hidden;
      width: 80% !important;
      background-color: white;
      position: relative;
      

              .add_form {
                max-height: 600px !important;
                overflow: scroll !important;
                display: flex;
                flex-direction: column;
                .add_form_wrap {
                  width: 100%;
                  display: grid;
                  grid-gap: 50px;
                  grid-template-columns: 1fr 1fr;
                  .form_in {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                  }
                  .input {
                    width: 100%;
                    height: auto;
                    padding: 10px;
                    display: flex;
                    flex-direction: column;
                    select {
                      margin-top: 5px;
                      width: 100%;
                      height: auto;
                      border-radius: 5px;
                      padding: 10px;
                      outline: none;
                      border: none;
                      // border: 1px solid #d6d6d6 !important;
                      background-color: rgba(229, 231, 235, 0.5058823529);
                    }
                    .photo_grid {
                      width: 100%;
                      height: auto;
                      gap: 10px;
                      display: grid;
                      grid-template-columns: 1fr 1fr;
                      .img_over {
                        border-radius: 5px;
                        width: 200px;
                        height: 200px;
                        display: flex;
                        overflow: hidden !important;
                        align-items: center;
                        justify-content: center;
                        object-fit: cover;
                        position: relative;

                        img {
                          display: flex;
                          object-fit: contain;
                          height: auto;
                          height: 100%;
                          width: auto;
                          overflow: hidden;
                        }
                        .trash_icon {
                          bottom: 3px;
                          padding: 3px;
                          border-radius: 50px;
                          right: 2px;
                          font-size: small !important;
                          position: absolute;
                          button {
                            height: auto;
                            outline: none;
                            border: none;
                            border-radius: 50%;
                            padding: 3px;
                            height: 22px;
                            cursor: pointer;
                            color: rgb(0, 0, 0);
                          }
                          button:hover {
                            background-color: rgba(0, 0, 0, 0.667);
                            color: white;
                          }
                        }
                        .star_icon {
                          bottom: 3px;
                          padding: 3px;
                          border-radius: 50px;
                          left: 2px;

                          font-size: small !important;
                          position: absolute;
                          button {
                            height: auto;
                            outline: none;
                            border: none;
                            border-radius: 50%;
                            height: 22px;
                            padding: 3px;
                            cursor: pointer;
                            color: rgb(0, 0, 0);
                          }
                          button:hover {
                            background-color: rgba(0, 0, 0, 0.667);
                            color: white;
                          }
                        }
                      }
                    }

                    input {
                      margin-top: 5px;
                      width: 100%;
                      height: auto;
                      padding: 10px;
                      outline: none;
                      border: none;
                      // border: 1px solid #d6d6d6 !important;
                      background-color: rgba(229, 231, 235, 0.5058823529);
                    }
                    textarea {
                      outline: none;
                      border: none;
                      margin-top: 5px;
                      border-radius: 5px;
                      padding: 5px 10px;
                      // border: 1px solid #d6d6d6 !important;
                      background-color: rgba(229, 231, 235, 0.5058823529);
                    }
                  }
                }

                .save {
                  width: 100%;
                  height: auto;
                  padding-top: 10px;
                  display: flex;
                  justify-content: flex-end;
                }
              }
              .close {
                width: 100%;
                position: absolute;
                top: 10px;
                right: 10px;
                display: flex;
                justify-content: flex-end;
              }
            }
          }
          .addNew_in_edit {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100dvh;
            z-index: 999;
            backdrop-filter: blur(2px);
            background-color: #1f2937d7;
            position: fixed;
            
            .addNew_wrap {
              height: 700px;
      border-radius: 5px;
      padding: 2rem;
      overflow: hidden;
      width: 80% !important;
      background-color: white;
      position: relative;

              .add_form {
                max-height: 600px !important;
                overflow: scroll !important;
                display: flex;
                flex-direction: column;
                .add_form_wrap {
                  width: 100%;
                  display: grid;
                  grid-gap: 50px;
                  grid-template-columns: 1fr 1fr;
                  .grid_form {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                  }
                  .add_form_wrap_grid {
                    display: grid;
                    grid-gap: 10px;
                    grid-template-columns: 1fr 1fr;
                  }
                  select {
                    margin-top: 5px;
                    width: 100%;
                    height: auto;
                    border-radius: 5px;
                    padding: 10px;
                    outline: none;
                    border: none;
                    // border: 1px solid #d6d6d6 !important;
                    background-color: rgba(229, 231, 235, 0.5058823529);
                  }
                  .input {
                    width: 100%;
                    height: auto;
                    padding: 5px;
                    display: flex;
                    flex-direction: column;
                    .photo_grid {
                      width: 100%;
                      height: auto;
                      gap: 10px;
                      display: grid;
                      grid-template-columns: 1fr 1fr;
                      .img_over {
                        border-radius: 5px;
                        width: 200px;
                        height: 200px;
                        display: flex;
                        overflow: hidden !important;
                        align-items: center;
                        justify-content: center;
                        object-fit: cover;
                        position: relative;

                        img {
                          display: flex;
                          object-fit: contain;
                          height: auto;
                          height: 100%;
                          width: auto;
                          overflow: hidden;
                        }
                        .trash_icon {
                          bottom: 3px;
                          padding: 3px;
                          border-radius: 50px;
                          right: 2px;
                          font-size: small !important;
                          position: absolute;
                          button {
                            height: auto;
                            outline: none;
                            border: none;
                            border-radius: 50%;
                            padding: 3px;
                            height: 22px;
                            cursor: pointer;
                            color: rgb(0, 0, 0);
                          }
                          button:hover {
                            background-color: rgba(0, 0, 0, 0.667);
                            color: white;
                          }
                        }
                        .star_icon {
                          bottom: 3px;
                          padding: 3px;
                          border-radius: 50px;
                          left: 2px;

                          font-size: small !important;
                          position: absolute;
                          button {
                            height: auto;
                            outline: none;
                            border: none;
                            border-radius: 50%;
                            height: 22px;
                            padding: 3px;
                            cursor: pointer;
                            color: rgb(0, 0, 0);
                          }
                          button:hover {
                            background-color: rgba(0, 0, 0, 0.667);
                            color: white;
                          }
                        }
                      }
                      label {
                        cursor: pointer;
                        width: 100%;
                        align-items: center;
                        justify-content: center;
                        display: flex;
                        height: auto;
                        height: 200px;
                        padding: 20px;
                        color: black;
                        border-radius: 5px;
                        border: 1px solid #aaaa;
                        // background-color: rgba(229, 231, 235, 0.5058823529);

                        .btn_upload {
                          width: 100%;
                          height: 100%;
                          display: none;
                        }
                      }
                      button:hover {
                        transition: 0.4s ease-in-out;
                        background-color: #ededed;
                      }
                    }

                    input {
                      margin-top: 5px;
                      width: 100%;
                      height: auto;
                      padding: 10px;
                      outline: none;
                      border: none;
                      // border: 1px solid #d6d6d6 !important;
                      background-color: rgba(229, 231, 235, 0.5058823529);
                    }
                    textarea {
                      outline: none;
                      border: none;
                      margin-top: 5px;
                      border-radius: 5px;
                      padding: 5px 10px;
                      // border: 1px solid #d6d6d6 !important;
                      background-color: rgba(229, 231, 235, 0.5058823529);
                    }
                  }
                }

                .save {
                  width: 100%;
                  height: auto;
                  padding-top: 10px;
                  display: flex;
                  justify-content: flex-end;
                }
              }
              .close {
                width: 100%;
                position: absolute;
                top: 10px;
                right: 10px;
                display: flex;
                justify-content: flex-end;
              }
            }
          }
        }
      }

      thead {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr 0.5fr 0.5fr;
        text-align: left;
        justify-content: space-evenly;
        border-bottom: 1px solid #e5e7eb !important;
        ._th {
          //   whitespace: nowrap;
          padding: 0.75rem 1rem;
          text-align: left !important;
          font-weight: 500;
          color: #222222;
          font-weight: 600;
          display: flex;
          //   border-right: 1px solid #e5e7eb !important;
        }
      }
      tbody {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr 0.5fr 0.5fr;
        width: 100%;
        text-align: left;
        border-bottom: 1px solid #e5e7eb !important;
        // margin-top: 10px;
        ._th {
          //   whitespace: nowrap;
          padding: 0.75rem 1rem;
          text-align: left !important;
          font-weight: 500;
          color: #4b5563;
          display: flex;
          //   border-right: 1px solid #e5e7eb !important;
          span {
            cursor: pointer;
          }
        }
      }
    }
  }
  .addPro_in {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100dvh;
    z-index: 99;
    backdrop-filter: blur(2px);
    background-color: #1f2937d7;
    overflow: hidden;
    position: fixed;

    .addNew_wrap {
       height: 700px;
      border-radius: 5px;
      padding: 2rem;
      overflow: hidden;
      width: 80% !important;
      background-color: white;
      position: relative;

      ._close {
        position: absolute;
        top: 10px;
        right: 10px;
        span {
          cursor: pointer;
          padding: 5px 20px;
          background-color: #3b82f6;
          border-radius: 5px;
          color: white;
        }
        span:hover {
          background-color: rgb(85, 150, 255);
          transition: 0.2s ease-in-out;
        }
      }
    }
  }
  .pro_head {
    margin-bottom: 20px;
  }
  ._product_item {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .image_list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      .image_wrap_in {
        width: 200px;
        border-radius: 5px;
        padding: 10px;
        img {
          width: 100%;
        }
        button {
          margin-top: 10px;
          border: 1px solid #aaaa;
          outline: none !important;
          border-radius: 5px;
          width: 100%;
          padding: 10px 20px;
          margin-bottom: 20px;
          cursor: pointer;
          border: none;
          transition: 0.3s;
          color: white;
          background-color: #1976d2;
        }
        button:hover {
          background-color: #d9d9d9;
          transition: 0.3s ease-in;
          color: rgb(49, 49, 49);
        }
      }
    }
  }
}
.loadingWrp{
   position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100dvh;
    z-index: 99999999 !important;
    backdrop-filter: blur(2px);
    background-color: #1f2937d7;
    overflow: hidden;
    position: fixed;
    img{
      width: 100px;
    }
    p{
      color: white !important;
    }

}
