.order_main {
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0px 10%;

  .order {
    width: 100%;
    height: 95dvh;
    padding: 40px;
    .table {
      cursor: pointer;
      width: 100%;
      border-radius: 5px;
      background-color: white;
      padding: 20px 40px;
      display: flex;
      flex-direction: column;
      border: 1px solid #e5e7eb !important;
      border-collapse: separate;
      border-spacing: 2px;
      border-color: #e5e7eb;
      font-size: 0.875rem;
    }
  }
}

.pro_head {
  margin-bottom: 20px;
  margin-left: 30px;
}

.addPro_in {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100dvh;
  z-index: 99;
  backdrop-filter: blur(2px);
  background-color: #1f2937d7;
  overflow: hidden;
  position: fixed;
  .addNew_wrap {
    padding: 40px 20px;
    width: 60%;
    border-radius: 5px;
    height: auto;
    background-color: white;
    position: relative;

    ._close {
      position: absolute;
      top: 10px;
      right: 10px;
      span {
        cursor: pointer;
        padding: 5px 20px;
        background-color: #3b82f6;
        border-radius: 5px;
        color: white;
      }
      span:hover {
        background-color: rgb(85, 150, 255);
        transition: 0.2s ease-in-out;
      }
    }
  }
}

.product_pro {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 40px;
  p {
    line-height: 1.6;
  }

  .customer {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
    .customer_in {
      width: 100%;
      height: auto;
    }
  }
  .customer_ad {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
    .customer_in {
      width: 100%;
      height: auto;
    }
  }
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  //   border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f0f0f0;
}
