.layout_head{
    width: 100dvw;
}
.layout_in{
    width: 100dvw;
    display: flex;
    align-items: center;
    justify-content: center;

  
}