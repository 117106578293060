.owl-prev,
.owl-next {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
}

.owl-prev {
  left: -1rem;
  width: 30px;
  height: 30px;
  display: block !important;
  background-color: #ffffffc3 !important;
  border-radius: 50px !important;
  transition: 0.2s ease-in-out;
}
.owl-prev:hover {
  background-color: #d9d9d9c3 !important;
  transition: 0.2s ease-in-out;
}

.owl-next {
  right: -1rem;
  width: 30px;
  height: 30px;
  display: block !important;
  background-color: #ffffffc3 !important;
  border-radius: 50px !important;
  transition: 0.2s ease-in-out;
}

.owl-next:hover {
  background-color: #d9d9d9c3 !important;
  transition: 0.2s ease-in-out;
}

.card_img {
  border-radius: 10px !important;
  height: 100px !important;
  overflow: hidden !important;
  object-fit: contain !important;
}
.card_info {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}
