.header{
    background-color: rgb(31 41 55);
    height: 4rem;
   padding: 0px 5%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .header_in{
       span{
        color: white;
        font-weight: 600;

       }
    }
}