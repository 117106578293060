.sideBar {
  width: 15dvw;
  height: 95vh;
  background-color: white;
  padding: 20px;
  .side_nav {
    width: 100%;
    padding: 20px;
    ul {
      font-style: none !important;
      text-decoration: none !important;
      li {
        list-style: none;
        margin-bottom: 20px;
         padding: 10px 20px;
         background-color: white;

         
      }
      li:hover{
        background-color: rgb(243 244 246);
        border-radius: 5px;
      }
      
    }
  }
}
